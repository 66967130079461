import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import MoreInfo from "./Components/MoreInfo/MoreInfo";
import ContactUs from "./Components/ContactDetails/ContactUs";
import App from "./App";
import "./index.css";

function RoutesContainer() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/more-info" element={<MoreInfo />} />
        <Route path="/ContactUs" element={<ContactUs />} />
      </Routes>
    </Router>
  );
}

export default RoutesContainer;
